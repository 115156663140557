import Vue from 'vue'
import Vuex from 'vuex'
import user from "@/store/modules/user";
import serialPort from '@/store/modules/serialPort';
import certColumns from '@/store/modules/certColumns.js';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
	serialPort,
	certColumns
  }
})
