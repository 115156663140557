import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import Home from '../views/Home.vue'
import User from '../views/admin/User.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main,
    redirect: {path: '/home'},
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: { title: '首页' }
      },{
        path: '/user',
        name: 'User',
        component: User,
        meta: { title: '用户管理', isTab: true }
      },{
        path: '/role',
        name: 'Role',
        component: ()=>import('../views/admin/Role.vue'),
        meta: { title: '角色管理', isTab: true }
      },{
        path: '/menu',
        name: 'Menu',
        component: ()=>import('../views/admin/Menu.vue'),
        meta: { title: '菜单权限', isTab: true }
      },{
        path: '/dept',
        name: 'Dept',
        component: ()=>import('../views/admin/Dept.vue'),
        meta: { title: '部门管理', isTab: true }
      },{
        path: '/log',
        name: 'Log',
        component: ()=>import('../views/system/Log.vue'),
        meta: { title: '日志管理', isTab: true }
      },{
        path: '/dict',
        name: 'Dict',
        component: ()=>import('../views/system/Dict.vue'),
        meta: { title: '数据字典', isTab: true }
      },{
        path: '/druid',
        name: 'Druid',
        component: ()=>import('../views/system/Druid.vue'),
        meta: { title: 'Druid', isTab: true }
      },{
        path: '/electronSerialPort',
        name: 'ElectronSerialPort',
        component: ()=>import('../views/grc/ElectronSerialPort.vue'),
        meta: { title: '串口测试', isTab: true }
      },

      {
        path: '/grc/receive',
        name: 'Receive',
        component: ()=>import('../views/grc/receive/Receive.vue'),
        meta: { title: ' 收 件 ', isTab: true, skipRefresh: false }
      },{
        path: '/grc/receive/printVirtual/list',
        name: 'PrintVirtualCode',
        component: ()=>import('../views/grc/receive/PrintVirtualCode.vue'),
        meta: { title: '虚拟编号打印', isTab: true }
      },{
        path: '/grc/payRecord',
        name: 'PayRecord',
        component: ()=>import('../views/grc/receive/PayRecord.vue'),
        meta: { title: '交付记录', isTab: true }
      },{
        path: '/grc/expressReceive',
        name: 'ExpressReceive',
        component: ()=>import('../views/grc/receive/ExpressReceive.vue'),
        meta: { title: '分配快递收件编码', isTab: true }
      },{
        path: '/grc/tagsCertInput',
        name: 'TagsCertInput',
        component: ()=>import('../views/grc/cert/TagsCertInput.vue'),
        meta: { title: '证书录入Tags', isTab: true, keepAlive: true }
      },{
        path: '/grc/tagsCertCheck',
        name: 'TagsCertCheck',
        component: ()=>import('../views/grc/cert/TagsCertCheck.vue'),
        meta: { title: '证书审核Tags', isTab: true }
      },{
        path: '/grc/certImage',
        name: 'CertImage',
        component: ()=>import('../views/grc/cert/CertImages.vue'),
        meta: { title: '图片管理', isTab: true }
      },{
        path: '/grc/tagsCertPrint',
        name: 'TagsCertPrint',
        component: ()=>import('../views/grc/cert/TagsCertPrint.vue'),
        meta: { title: '查询与打印Tags', isTab: true }
      },{
        path: '/grc/vip',
        name: 'Vip',
        component: ()=>import('../views/grc/vip/Vip.vue'),
        meta: { title: '会员管理', isTab: true }
      },{
        path: '/grc/tradeSum',
        name: 'TradeSum',
        component: ()=>import('../views/grc/vip/TradeSum.vue'),
        meta: { title: '账目汇总', isTab: true }
      },{
        path: '/grc/trade',
        name: 'trade',
        component: ()=>import('../views/grc/vip/TradeRecord.vue'),
        meta: { title: '账目明细', isTab: true }
      },{
        path: '/grc/vip/impList',
        name: 'VipImportList',
        component: ()=>import('../views/grc/vip/VipImport.vue'),
        meta: { title: '会员Excel导入', isTab: true }
      },{
        path: '/grc/certStat',
        name: 'CertStat',
        component: ()=>import('../views/grc/stat/CertStat.vue'),
        meta: { title: '出证统计', isTab: true }
      },{
        path: '/grc/ReceiveStat',
        name: 'ReceiveStat',
        component: ()=>import('../views/grc/stat/ReceiveStat.vue'),
        meta: { title: '收件统计', isTab: true }
      },{
        path: '/grc/pearlCE',
        name: 'PearlCE',
        component: ()=>import('../views/grc/lab/PearlCE.vue'),
        meta: { title: '珍珠中英文', isTab: true }
      },{
        path: '/grc/certQRCode',
        name: 'QRCode',
        component: ()=>import('../views/grc/lab/CertQRCode.vue'),
        meta: { title: '生成二维码', isTab: true }
      },{
        path: '/grc/dataLink',
        name: 'DataLink',
        component: ()=>import('../views/grc/lab/DataLink.vue'),
        meta: { title: '数据联动配置', isTab: true }
      },{
        path: '/grc/checkOperator',
        name: 'CheckOperator',
        component: ()=>import('../views/grc/lab/CheckOperator.vue'),
        meta: { title: '互检操作', isTab: true }
      },{
        path: '/grc/removeCert',
        name: 'RemoveCert',
        component: ()=>import('../views/grc/receive/RemoveCert.vue'),
        meta: { title: '作废证书', isTab: true }
      },


      {
        path: '/customer',
        name: 'Customer',
        component: ()=>import('../views/crm/Customer.vue'),
        meta: { title: '客户管理', isTab: true }
      },{
        path: '/contract',
        name: 'Contract',
        component: ()=>import('../views/crm/Contract.vue'),
        meta: { title: '合同管理', isTab: true }
      },{
        path: '/product',
        name: 'Product',
        component: ()=>import('../views/crm/Product.vue'),
        meta: { title: '物料管理', isTab: true }
      },{
        path: '/orders',
        name: 'Orders',
        component: ()=>import('../views/crm/OrderForGoods.vue'),
        meta: { title: '订单管理', isTab: true }
      },


    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: "/404",
    name: "NotFound",
    component: () => import('../views/system/404.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/404"
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

//全局前置守卫
router.beforeEach((to, from, next) => {
	// 如果URL是图片，直接放行
  if (/\.(?:jpg|jpeg|gif|png|svg|webp)$/.test(to.path)) {
	next();
  } 
  let token = sessionStorage.getItem("token")
  if(token) { //已登陆
    if(to.path == '/login') {
      Vue.prototype.$message.warning("您已登陆，请不要重复登陆!")
      return next({ name: from.name ? from.name : 'index'});
    } else {
		//TODO... 可以做其他验证
		if(to.query.skipRefresh) {
			//next(false);
			next();
		} else {
			next();
		}
	}
  } else {
    //未登陆的情况下先判断将要抵达的是不是login页面，如果是直接放行即可
    if(to.path === '/login') {
      return next();
    }
    Vue.prototype.$message.error('请先登陆... ...')
    next({path: '/login'})
  }
})

export default router
