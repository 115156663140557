<template>
  <div id="app">
	  <keep-alive>
		  <router-view v-if="$route.meta.KeepAlive"></router-view>
	  </keep-alive>
      <router-view v-if="!$route.meta.KeepAlive"></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
  mounted() {
	  if(sessionStorage.getItem('deptName') !== undefined && sessionStorage.getItem('deptName') !== null) {
		  window.document.title = sessionStorage.getItem('deptName')
	  } else {
		  window.document.title = 'GRC'
	  }
  }
}
</script>

<style>
</style>
